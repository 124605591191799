import React, {useState, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import base_url, {urlSearchParams} from '../../baseUrls';
import Utils from '../../utils/utils';
import {roleTranslator} from '../../utils/translator';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {CONTRACTOR_ELEMENTS, contractorUserPageRules} from '../../permissions/contractorUserPageAccessControl';
import * as allConstants from '../../constants/allConstants';
import {useDispatch} from 'react-redux';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import actions from '../../redux/actions';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import {USER_FIELDS} from '../../constants/allConstants';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import {useNavigate} from 'react-router-dom';
import {MaximizableCellRenderer} from '../../pieces/grids/MaximizableCellRenderer';
import {canBeAdmin} from './setCompanyAdmin';
import {companyRules, COMPANY_ELEMENTS} from '../../permissions/companyAccessControl';
import {useServiceTitanAlias} from '../../hooks/useServiceTitanAlias';
import axios from 'axios';
import ApiUtils from '../../utils/apiUtils';

const AllUsersGrid = (props) => {
  const viewController = props.viewController;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [components] = useState({
    'statusRenderer': StatusRenderer,
    'MaximizableCellRenderer': MaximizableCellRenderer,
  });

  const companyId = props.overrideCompanyId ? props.overrideCompanyId : viewController.companyId 

  const dlgAlias = useServiceTitanAlias({
    title: 'Service Titan Alias ',
    fieldName: 'stCreateJobEmail',
    fieldPath: 'stCreateJobEmail',
    companyId: companyId,
    apiUrl: 'companies/change-st-create-job-email',
  }); 

  //console.log('All Users Grid props');
  //console.log(props);

  let columnDefs = useMemo(()=>{
    return [
      new ColumnDefinition({field: allConstants.USER_FIELDS.ID, hide: true}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.COMPANY_ID, hide: true}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.NAME, 
        lockPinned: true,
        pinned: 'left',
        valueGetter: ({data})=> {return `${data.firstName} ${data.lastName}`;},
        checkboxSelection: true,
        cellRenderer: 'MaximizableCellRenderer',
        cellRendererParams: (params) => {
          return {
            handleExpand: ()=>editUserHandler(params.data),
          };
        },
      }),
      new ColumnDefinition({field: allConstants.USER_FIELDS.PHONE_NUMBER,
        valueGetter: (({data})=> {
          return Utils.formatPhoneNumber(data[allConstants.USER_FIELDS.PHONE_NUMBER.api_name]);

        })
      }),
      new ColumnDefinition({field: allConstants.USER_FIELDS.EMAIL}),
      //new ColumnDefinition({field: allConstants.USER_FIELDS.ST_CREATE_JOB_EMAIL}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.ST_CREATE_JOB_USER}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.COMPANY_NAME, hide: props.overrideEditUser != undefined, width: 300, wrapText: true}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.TITLE}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.ROLE,
        valueGetter: (({data})=>  roleTranslator.translate(data.role)),
        filter:'agSetColumnFilter',
        filterValues: Object.values(allConstants.CONTRACTOR_ROLES).map((r)=> roleTranslator.translate(r)),
      }),

      new ColumnDefinition({field: allConstants.USER_FIELDS.STATUS, cellRenderer: 'statusRenderer',
        filter:'agSetColumnFilter',
        filterValues: Object.values(allConstants.USER_STATUSES).filter((status)=> status.value!==allConstants.USER_STATUSES.NOT_ACTIVE.value).map((s)=> s.value.toUpperCase())
      })
    ];
  }, []);

  const impersonate = (companyId)=> { 
    dispatch(actions.impersonate(companyId));
  };

  const impersonateUser = async (userId, role, companyId, userCompanies) => {
    const impersonateResult = await dispatch(actions.impersonateUser( {userId, role, companyId, userCompanies}));
    
    if(impersonateResult?.meta?.requestStatus === 'fulfilled') {
      navigate(`${allConstants.PATH.JOBS}?${urlSearchParams.companyId}=${companyId}`);
    }
  }

  const changeAdminHandler = (newAdmin)=> {
    props.changeAdminHandler(newAdmin);
  };
  // Thanh 02/04/24
  const inviteUserHandler = (userData)=> {
    

    if (props.overrideInviteUser) {
      props.overrideInviteUser(userData);
    }
  };

  const editUserHandler = (userData)=> {
    
    if (props.overrideEditUser) {
      props.overrideEditUser(userData.id);
    }
    else {
      navigate(`${allConstants.PATH.USER}${location.search}&${urlSearchParams.userId}=${userData.id}`);      
    }
  };


  const getActiveUserList = async (companyId) => { 

    const authToken =  await ApiUtils.getAccessToken();
    const url = `${base_url.api}users/get-service-titan-user-emails`;
    const newItems = await axios.get(url, {params: { companyId: companyId }, headers: {Authorization: authToken}});

    return newItems.data.data;
  }

  // const getSelectedRows = () => {
  //   const selectedNodes = gridRef.current.api.getSelectedNodes();
  //   const selectedData = selectedNodes.map(node => node.data);
  //   console.log(selectedData);
  // };

  const setServiceTitanCreateJobUserHandler = (params) => {

    const companyId = props.overrideCompanyId ? props.overrideCompanyId : viewController.companyId 
    
    getActiveUserList (companyId).then (userList => {
      
        // console.log('about to show dialog');
        // console.log(params);
        // console.log('list of active users from server');
        // console.log(userList);

        dlgAlias.showDialog(userList, params);

          //allConstants.USER_FIELDS.ST_CREATE_JOB_EMAIL.api_name,
         // { 'companyId': companyId, 'email': params.node.data[allConstants.USER_FIELDS.EMAIL.api_name]});
    }); 
  }

  const resetPasswordUserHandler = (userData)=> {
    props.resetPasswordUserHandler(userData);
  };

  // eslint-disable-next-line no-unused-vars
  const deleteUser = (userId)=> {
    //** todo: open modal and confirm action from there
  };

  /**
   * @param {object} userData
   * @return {Promise<void>}
   */
  const deactivateUser = async (userData)=> {
    props.deactivateUserHandler(userData);
  };

  const activateUser = async (userId, userData) => {
    const activateStatus = await dispatch(actions.activateUser({userId: userId}));
    unwrapRequestResult({
      result: activateStatus,
      successMessage: `Activated: ${userData?.[USER_FIELDS.FIRST_NAME.api_name]} ${userData?.[USER_FIELDS.LAST_NAME.api_name]}.`,
      errorMessage: 'Error on activation',
      showSuccess: true,
    } );
    viewController.refreshOnSuccess(activateStatus);
  };

  const reinvite = async (userId, userData) => {
    const reinviteStatus = await dispatch(actions.reinviteUser({userId: userId}));
    unwrapRequestResult({
      result: reinviteStatus,
      successMessage: `New invite link sent to user ${userData?.[USER_FIELDS.FIRST_NAME.api_name]} ${userData?.[USER_FIELDS.LAST_NAME.api_name]}.`,
      //errorMessage: 'Error on re-invite',
      showSuccess: true,
    } );
  };

  const getContextMenuItems = (params)=> {
    // console.log('params', params?.node?.data, user?.id);
    if(!params.node) return [];
    const userStatus = params?.node?.data[allConstants.USER_FIELDS.STATUS.api_name];
    const userRole = params?.node?.data[allConstants.USER_FIELDS.ROLE.api_name];
    let clickOnSelf = params?.node?.data && params?.node?.data.id === props.viewController.user.id;
    const userSignedUp = Utils.notEqualsIgnoreCase(userStatus, allConstants.USER_STATUSES.INVITED.value);

    const actions = {
      impersonate:  {
        name: 'Impersonate',
        disabled: !userSignedUp || viewController.selectedMultipleRows() || !NewPermissionGate({
          user: viewController.user,
          elementPermissions: companyRules[COMPANY_ELEMENTS.impersonate]
        }),
        action: ()=> {

          //console.log('selected user data');
          //console.log(params?.node?.data);

          impersonateUser(params?.node?.data.id, params?.node?.data.role, params?.node?.data.companyId, params?.node?.data.userCompanies);
        },
        cssClasses: ['redFont', 'bold'],
      },
      // impersonate:  {
      //   name: 'Impersonate',
      //   disabled: !userSignedUp || viewController.selectedMultipleRows() || !NewPermissionGate({
      //     user: viewController.user,
      //     elementPermissions: companyRules[COMPANY_ELEMENTS.impersonate]
      //   }),
      //   action: ()=> {
      //     impersonate(params?.node?.data.companyId);
      //   },
      //   cssClasses: ['redFont', 'bold'],
      // },
      reInvite: {
        name: 'Re-send Invite',
        disabled: viewController.selectedMultipleRows() || userSignedUp || !NewPermissionGate({
          user: viewController.user,
          elementPermissions: companyRules[COMPANY_ELEMENTS.inviteCompany]
        }),
        action: ()=> {
          reinvite(params?.node?.data[USER_FIELDS.ID.api_name], params?.node?.data);
        },
        cssClasses: ['blueFont', 'bold'],
      },
      resetPassword: {
        name: 'Reset Password',
        disabled: viewController.selectedMultipleRows() || !userSignedUp || !NewPermissionGate({
          user: viewController.user,
          elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.resetPassword]
        }),
        action: () => {
          resetPasswordUserHandler(params?.node?.data);
        },
      },
      editUser: {
        name: 'View/Edit',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> {
          editUserHandler(params?.node?.data);
        },
      },
      serviceTitanCreateJobUser: {
        name: 'Assign to Alias',
        //disabled: viewController.selectedMultipleRows(),
        action: ()=> {
          setServiceTitanCreateJobUserHandler(params);
        },
      },

      // Thanh 02/04/24
      inviteUser: {
        name: 'Invite',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> {
          inviteUserHandler(params?.node?.data);
        },
      },
      disableUser: {
        name: 'Disable User',
        disabled: clickOnSelf || viewController.selectedMultipleRows() || !NewPermissionGate({
          user: viewController.user,
          elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.manageUsers]
        }),
        action: ()=> {
          deactivateUser(params?.node?.data);
        }
      },
      activateUser: {
        name: 'Activate User',
        disabled: clickOnSelf || viewController.selectedMultipleRows()
          || !NewPermissionGate({
            user: viewController.user,
            elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.manageUsers]
          }),
        action: ()=> {
          activateUser(params?.node?.data[USER_FIELDS.ID.api_name], params?.node?.data);
        }
      },
      setCompanyAdmin: {
        name: 'Set Company Admin',
        disabled: clickOnSelf || !canBeAdmin(userStatus, userRole) || viewController.selectedMultipleRows() || !NewPermissionGate({
          user: viewController.user,
          elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.changeCompanyAdmin]
        }),
        action: ()=> {
          changeAdminHandler(params?.node?.data);
        }
      },

    };

    const ipermitOptions = [
      userSignedUp ? actions.impersonate : actions.reInvite,
    ];

    const userActions = [];

    if (userStatus === allConstants.USER_STATUSES.NOT_EXIST.value.toUpperCase()) {
      userActions.push(actions.inviteUser);
    }
    else {
      userActions.push(actions.editUser);
    }
    if(userStatus === allConstants.USER_STATUSES.DISABLED.value.toUpperCase()) {
      // console.log('will add activation');
      userActions.push(actions.activateUser);
    } else {
      userActions.push(actions.disableUser);
    }

    if(NewPermissionGate({
      user: viewController.user,
      elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.changeCompanyAdmin],
    })) {
      userActions.push(actions.setCompanyAdmin);
    }


    if(NewPermissionGate({
      user: viewController.user,
      elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.resetPassword],
    })) {
      userActions.push(actions.resetPassword);
    }


    if(NewPermissionGate({
      user: viewController.user,
      elementPermissions: contractorUserPageRules[CONTRACTOR_ELEMENTS.setCompanyAdmin],
    })) {
      userActions.push(actions.serviceTitanCreateJobUser);
    }

    const standard = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];
    const separator=[
      'separator'
    ];

    const dangerous = [];

    // if(PermissionGate({
    //   modulePermissions: userPageRules.deleteUser, scope: allConstants.SCOPES.canView, role: viewController.userRole})){
    //   dangerous.push('separator');
    //   dangerous.push({
    //     name: 'Delete User',
    //     action: ()=> deleteUser(params?.node?.data)
    //   });
    // }
    if(NewPermissionGate({
      user: viewController.user,
      elementPermissions: companyRules[COMPANY_ELEMENTS.impersonate],
    })) {
      return [...ipermitOptions,...separator, ...userActions, ...separator, ...standard, ...dangerous];
    }
    return [...userActions, ...separator, ...standard, ...dangerous];
  };

  const modifyElement = (el) => {

    if(el[allConstants.USER_FIELDS.IS_BLOCKED.api_name]) {
      el[allConstants.USER_FIELDS.STATUS.api_name] = allConstants.USER_STATUSES.BLOCKED.value;
    }
    return el;

  };

  const ls_name = viewController.user.companyId ? 'company_users_grid': 'all_users_grid';

  //console.log('viewcontroller');
  //console.log(viewController);

  return (
    <>
    {dlgAlias.mainDialog()}
    
    <MyPaginatedGrid columnDefs={columnDefs}
      onCellDoubleClicked={(params)=> editUserHandler(params.data)}
      components={components}
      contextMenu={getContextMenuItems}
      ls_name={ls_name}
      registerGrid={viewController.setGridToParent}
      //fetchParams={{url: `${base_url.api}users/get-contractor-users`, respKey:'users'}}
      fetchParams={props.fetchParams}
      companyId={props.overrideCompanyId ? props.overrideCompanyId : viewController.companyId}
      modifyDataElement={modifyElement}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
      refreshTimestamp={viewController.refreshTimestamp}
      isTabOffset={props?.isTabOffset}
    />
  </>
  );
};

AllUsersGrid.propTypes = {
  resetPasswordUserHandler: PropTypes.func.isRequired,
  changeAdminHandler: PropTypes.func.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  viewController: PropTypes.instanceOf(Object).isRequired,
  deactivateUserHandler: PropTypes.func.isRequired,

};
export default AllUsersGrid;
