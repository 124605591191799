import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../constants/allConstants';
import {Box} from '@mui/material';
import CoolingSystem from './panels/coolingSystem';
import HpuSystem from './panels/hpuSystem';
import Ducts from './panels/ducting';
import TabsBlock from '../../../../pieces/tabsBlock';
import {useCreateJobStyles} from '../createJobStyles';
import {useFormContext} from 'react-hook-form';
import Utils from '../../../../utils/utils';
import actions from '../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';


const HvacFormBlock = (props)=> {
  // console.log('hvac', props);
  const classes = {...useCreateJobStyles()};
  const dispatch = useDispatch();
  const existingSubforms = useSelector(state => state.subforms);
  const MECH_FIELDS = allConstants.JOB_FIELDS.MECHANICAL;
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const panels = {
    [MECH_FIELDS.COOLING_SYSTEM.api_name]:CoolingSystem,
    [MECH_FIELDS.HPU_SYSTEM.api_name]: HpuSystem,
    [MECH_FIELDS.DUCTS.api_name]:Ducts,
  };
  const fields = [
    MECH_FIELDS.COOLING_SYSTEM,
    MECH_FIELDS.HPU_SYSTEM, MECH_FIELDS.DUCTS
  ];
  const control = useFormContext();
  const canModifyEquipment = useRef(Utils.canModifyEquipment(props.draftData));

  useEffect(()=> {
    let draftData;
    try {

      console.log("hvacFromBlaock - inside useEffect- init")

      draftData = JSON.parse(props.draftData?.[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name])?.equipment;
   
      console.log('draftData');
      console.log(draftData);
   
      const currentSubforms = existingSubforms?.[allConstants.JOB_TYPES.HVAC_RES.api_name];
      if(draftData && (!currentSubforms || Utils.objectIsEmpty(currentSubforms))) {
        const subforms = {};
        Object.keys(panels).forEach((system)=> {
          subforms[system] = Utils.createSystemDataForRedux(draftData, system);
        });
        dispatch(actions.addBulkSubforms({jobType: allConstants.JOB_TYPES.HVAC_RES.api_name,
          subforms
        }));
      }
    } catch (e){
      //don't remove try catch block
      console.log('error', e);
    }
    setIsFirstLoad(false);
  },[]);

  const content = fields.map((f)=> {
    return {
      name: f.api_name,
      label: f.label,
      errors: props.systemErrors?.[f.api_name],
      panel: ()=>panels[f.api_name]({
        systemErrors: props.systemErrors,
        setEquipmentForm: props.setEquipmentForm,
        canModifyEquipment: canModifyEquipment.current,
        resetSystemForm: props.resetSystemForm,
        saveSelectedEquipment: control.setValue,     //use main form
        getSelectedEquipment: control.getValues,      //use main form
      })
    };
  });

  if(isFirstLoad) {
    //don't render subforms before we set draft data, is exists to store
    return null;
  }

  return(
    <Box className={classes.tab_container}>
      <TabsBlock content={content}  padding={'0'}/>
    </Box>
  );
};

HvacFormBlock.propTypes = {
  setEquipmentForm: PropTypes.func.isRequired,
  resetSystemForm: PropTypes.func.isRequired,
  systemErrors: PropTypes.instanceOf(Object),
  draftData: PropTypes.string,
};

HvacFormBlock.defaultProps = {
  systemErrors: {},
  draftData: null,
};

export default HvacFormBlock;
