import {completeOnboarding} from './thunks/completeOnboarding';
import {createPassword} from './thunks/createPasswordThunk';
import {activateUser, deactivateUser} from './thunks/activateDeactivateUser';
import {impersonate, depersonate, impersonateUser,  depersonateUser} from './thunks/impersonateThunk';
import {validateUserInvite} from './thunks/validateInvite';
import {updateAdmin} from './thunks/updateAdmin';
import {signInUser} from './thunks/signIn';
import {signUpUser} from './thunks/signUp';
import {signOut} from './thunks/signOut';
import {getCurrentUserData} from './thunks/getCurrentUserData';
import {forgotUserPassword} from './thunks/forgotPassword';
import {inviteUser, reinviteUser} from './thunks/inviteUser';
import {editUserById} from './thunks/editUserById';
import {changeUserPassword} from './thunks/changeUserPassword';
import {connectUserToCheers} from './thunks/connectUserToCheers';

const userThunks = {
  completeOnboarding,
  createPassword,
  changeUserPassword,
  activateUser,
  deactivateUser,
  impersonate,
  impersonateUser,
  depersonate,
  depersonateUser,
  validateUserInvite,
  updateAdmin,
  signInUser,
  signUpUser,
  signOut,
  getCurrentUserData,
  forgotUserPassword,
  inviteUser,
  reinviteUser,
  editUserById,
  connectUserToCheers,
};

export default userThunks;
