import {CONTRACTOR_ROLES, IPERMIT_ROLES} from '../constants/roles';

export const isImpersonating = (role, companyId, impersonateUserId)=> {
  if(!role) return false;

  // console.log('role => ', role);
  // console.log('company Id => ', companyId);
  // console.log('impersonate user id => ',impersonateUserId);

  return Object.values(IPERMIT_ROLES).includes(role) && !!companyId && impersonateUserId == undefined;
};

export const isImpersonatingUser = (role, impersonateUserId)=> {
  if(!role) return false;


  return impersonateUserId != undefined;
};

export const isIpermitUser = (role)=> {
  return Object.values(IPERMIT_ROLES).includes(role);
};

export  const isContractor = (role)=> {
  return Object.values(CONTRACTOR_ROLES).includes(role);
};
