import React from 'react';
import {Box, Typography, Icon, Grid} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import clsx from 'clsx';
import {globalUseClasses} from '../../styles/globalClasses';
import {useHomePageStyles} from '../home/homePageStyles';
import {CustomMediaQuery} from '../../hooks/customMediaQuery';
import Utils from '../../utils/utils';

const QuickbooksCallback = () => {

  const queries = CustomMediaQuery();
  const classes = {...useHomePageStyles(queries)(), ...globalUseClasses()};
  const quickbooksCode  = Utils.getUrlParameter('code') ?? '';

  console.log('inside QuickbooksCallback');
  console.log('quickbooks code => ', quickbooksCode);

  return (
    <>
      <NavBar/>
      <Box className={clsx(classes.home_content_container, classes.page)} sx={{
        height: '100%'}}>
          <Typography variant={'subtitle2'}>
            {quickbooksCode}
          </Typography>
      </Box>
    </>

  ) 
}
export default QuickbooksCallback;