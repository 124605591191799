import {createSlice} from '@reduxjs/toolkit';
import {activateReducer, deactivateReducer} from '../user/thunks/activateDeactivateUser';
import {blockCompanyReducer, unblockCompanyReducer} from './thunks/blockUnblockCompany';
import {editCompanyReducer} from './thunks/editComapny';
import {inviteCompanyReducer} from './thunks/inviteCompany';
import {initialCompanyState, initialServerState} from './initialStates';
import {clearState} from '../generalActions';
import {getCurrentUserCompanyReducer} from '../user/thunks/getCurrentUserData';
import {depersonateCompanyReducer, depersonateUserCompanyReducer, impersonateCompanyReducer, impersonateUserCompanyReducer} from '../user/thunks/impersonateThunk';
import {selfRegisterCompanyReducer} from './thunks/selfRegisterCompany';
import {acceptCompanyRegistrationReducer, rejectCompanyRegistrationReducer} from './thunks/verifyCompany';


export const companySlice = createSlice({
  name: 'company',
  initialState: {...initialCompanyState, ...initialServerState},
  reducers: {
    userLogout: (state, {payload})=> {
      return {...initialServerState, ...initialCompanyState};
    },
    updateCompanyState: (state, {payload})=> {
      return {...state, ...payload};
    },
    resetCompanyState: (state)=> {
      return  {...initialCompanyState, ...initialServerState};
    },
  },
  extraReducers: {
    ...activateReducer,
    ...deactivateReducer,
    ...blockCompanyReducer,
    ...unblockCompanyReducer,
    ...editCompanyReducer,
    ...getCurrentUserCompanyReducer,
    ...impersonateCompanyReducer,
    ...impersonateUserCompanyReducer,
    ...depersonateCompanyReducer,
    ...depersonateUserCompanyReducer,
    ...inviteCompanyReducer,
    ...selfRegisterCompanyReducer,
    ...acceptCompanyRegistrationReducer,
    ...rejectCompanyRegistrationReducer,
    [clearState]: () => {
      return {...initialCompanyState, ...initialServerState};
    },
  }
});
export const {userLogout, updateCompanyState, resetCompanyState} = companySlice.actions;
export const companySelector = state => state.company;
