import {createAsyncThunk} from '@reduxjs/toolkit';
import ApiUtils from '../../../utils/apiUtils';
import base_url from '../../../baseUrls';
import {cleanedServerState, initialUserState} from '../userInitialStates';
import {initialCompanyState} from '../../companies/initialStates';
import { ContactlessOutlined, ContentPasteSearchOutlined } from '@mui/icons-material';

export const impersonate = createAsyncThunk(
  'companies/impersonate',
  async ( props, thunkAPI) => {
    try {
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }
      const response = await fetch(
        `${base_url.api}companies/impersonate-as-company-admin`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify({companyId:props}),
        }
      );
      let data = await response.json();
      // console.log('impersonate response',response, response.status === 200);
      if (response.status === 200) {
        return data.data;
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const impersonateCompanyReducer = {
  [impersonate.fulfilled]: (state, {payload}) => {
    const newState = {...state, ...payload.company};
    return newState;
  },
};

export const impersonateReducer = {

  [impersonate.pending]: (state)=>{

    state.isFetching = true;
  },
  [impersonate.fulfilled]: (state, {payload})=>{

    const newState = {...state, ...cleanedServerState, ...{companyId: payload.company.id}};
    return newState;
  },
  [impersonate.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};
export const depersonate = createAsyncThunk(
  'companies/depersonate',
  async ( props, thunkAPI) => {
    try {
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }

      const response = await fetch(
        `${base_url.api}companies/leave-impersonate-as-company-admin`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      let data = await response.json();
      // console.log('getCurrentUserData response',response, response.status === 200);

      if (response.status === 200) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const depersonateCompanyReducer = {
  [depersonate.fulfilled]: (state, {payload}) => {
    const newState = {...state, ...initialCompanyState};
    return newState;
  },
};
export const depersonateReducer = {
  [depersonate.pending]: (state)=>{
    state.isFetching = true;
  },
  [depersonate.fulfilled]: (state, {payload})=>{
    const newState = {...state, ...cleanedServerState, ...{companyId: initialUserState.companyId}};
    console.log();
    return newState;
  },
  [depersonate.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};


export const impersonateUser = createAsyncThunk(
  'users/impersonateUser',
  async ( props, thunkAPI) => {
    try {
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }
      const response = await fetch(
        `${base_url.api}users/impersonate-user`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },        
          body: JSON.stringify({
            impersonateUserId: props.userId,
            impersonateRole: props.role,
            companyId: props.companyId,
            userCompanies: props.userCompanies,
          }),
        }
      );
      let data = await response.json();

      //console.log('impersonate user response',response, response.status === 200);
      //console.log(data);

      if (response.status === 200) {
        return data.data;
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const impersonateUserCompanyReducer = {
  [impersonateUser.fulfilled]: (state, {payload}) => {
    const newState = {...state, ...payload.company};
    return newState;
  },
};
export const impersonateUserReducer = {
  [impersonateUser.pending]: (state)=>{
    state.isFetching = true;
  },
  [impersonateUser.fulfilled]: (state, {payload})=>{
    const newState = {...state, ...cleanedServerState,
             ...{companyId: payload.company.id, impersonateUserId: payload.impersonateUserId, role: payload.role,  userCompanies: payload.userCompanies}};
  
    // console.log('impersonate user reducer - Payload');
    // console.log(payload);
    // console.log('new state');
    // console.log(newState);

    return newState;
  },
  [impersonateUser.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};

export const depersonateUser = createAsyncThunk(
  'users/depersonateUser',
  async ( props, thunkAPI) => {
    try {
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }

      const response = await fetch(
        `${base_url.api}users/leave-impersonate-user`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      let data = await response.json();
      // console.log('getCurrentUserData response',response, response.status === 200);

      if (response.status === 200) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const depersonateUserCompanyReducer = {
  [depersonateUser.fulfilled]: (state, {payload}) => {
    const newState = {...state, ...initialCompanyState, ...{companyId: null}};
    return newState;
  },
};
export const depersonateUserReducer = {
  [depersonateUser.pending]: (state)=>{
    state.isFetching = true;
  },
  [depersonateUser.fulfilled]: (state, {payload})=>{
    const newState = {...state, ...cleanedServerState,
                      ...{companyId: null, role: payload.role, impersonateUserId: null, impersonateRole: null}, 
                     };  
    
    //console.log('new state after depersonting');
    //console.group(newState);
    
    return newState;
  },
  [depersonateUser.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMessage = payload?.message;
  },
};
