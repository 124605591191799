import {createSlice} from '@reduxjs/toolkit';
import {completeOnboardingReducer} from './thunks/completeOnboarding';
import {createPasswordReducer} from './thunks/createPasswordThunk';
import {initialUserState, initialServerState} from './userInitialStates';
import {activateReducer, deactivateReducer} from './thunks/activateDeactivateUser';
import {impersonateReducer, depersonateReducer, impersonateUserReducer, depersonateUserReducer} from './thunks/impersonateThunk';
import {validateInviteReducer} from './thunks/validateInvite';
import {updateAdminReducer} from './thunks/updateAdmin';
import {signInReducer} from './thunks/signIn';
import {signUpReducer} from './thunks/signUp';
import {signOutReducer} from './thunks/signOut';
import {getCurrentUserDataReducer} from './thunks/getCurrentUserData';
import {forgotPasswordReducer} from './thunks/forgotPassword';
import {inviteUserReducer, reinviteUserReducer} from './thunks/inviteUser';
import {clearState} from '../generalActions';
import {editUserByIdReducer} from './thunks/editUserById';
import {selfRegisterUserCompanyReducer} from '../companies/thunks/selfRegisterCompany';
import {changeUserPasswordReducer} from './thunks/changeUserPassword';
import {connectUserToCheersReducer} from './thunks/connectUserToCheers';


const initialState = {
  ...initialUserState, ...initialServerState,
  inviteLinkIsValid: false,
  isAuthenticated: false,
  resetPasswordEmailSent: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {...initialState},
  extraReducers: {
    ...updateAdminReducer,
    ...impersonateReducer,
    ...impersonateUserReducer,
    ...depersonateReducer,
    ...depersonateUserReducer,
    ...changeUserPasswordReducer,
    ...activateReducer,
    ...deactivateReducer,
    ...validateInviteReducer,
    ...signUpReducer,
    ...signInReducer,
    ...signOutReducer,
    ...getCurrentUserDataReducer,
    ...forgotPasswordReducer,
    ...createPasswordReducer,
    ...completeOnboardingReducer,
    ...inviteUserReducer,
    ...reinviteUserReducer,
    ...editUserByIdReducer,
    ...selfRegisterUserCompanyReducer,
    ...connectUserToCheersReducer,
    [clearState]: () => {
      return {...initialState};
    },
  },
});




