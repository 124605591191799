import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import myTheme from '../../styles/myStyles';
import {useNavbarStyles} from './navbarStyles';
import {ThemeProvider} from '@mui/material/styles';
import * as allConstants from '../../constants/allConstants';
import {globalUseClasses} from '../../styles/globalClasses';
import Utils from '../../utils/utils';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import ipermitLogo from '../../static/images/iPermitPro.png';
import {AllLinks} from './links';
import MenuItem from '@mui/material/MenuItem';
import burgerIcon from '../../static/images/burger-icon.svg';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import actions from '../../redux/actions';
import * as companySlice from '../../redux/companies/companySlice';
import {AppBar, Toolbar, Button, Menu, Divider} from '@mui/material';
import  {NewPermissionGate} from '../../permissions/permissionGate';
import {ipermitAdminRules, ADMIN_ELEMENTS} from '../../permissions/ipermitAdminAccessControl';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {Hidden} from '@mui/material';
import {isContractor, isImpersonating, isImpersonatingUser} from '../../permissions/utils';
import {urlSearchParams} from '../../baseUrls';
import {COMPANY_ELEMENTS, companyRules} from '../../permissions/companyAccessControl';
import {useAddTag} from '../../hooks/useAddTag';

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const company = useSelector(state=> state.company);


  //console.log('user selector for company inside navbar');
  //console.log(company);
  //console.log(user);

  //const tagView = useAddTag();     // Thanh 02/04/24
  const navigate=useNavigate();
  const location =useLocation();
  const username = user.userName;
  const usernameString = `${username.firstName ? username.firstName.toUpperCase() : ''} ${username.lastName?username.lastName.toUpperCase(): ''}`;
  const userAcronym = Utils.getAcronym(usernameString).charAt(0)?.toUpperCase();
  const classes = {...useNavbarStyles(), ...globalUseClasses()};

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const depersonate = async ()=> {
    const result = await dispatch(actions.depersonate());
    if(result?.meta?.requestStatus === 'fulfilled'){
      if(location.pathname === allConstants.PATH.COMPANY){
        navigate(allConstants.PATH.ALL_COMPANIES);
      } else if(location.pathname === allConstants.PATH.JOBS || location.pathname === allConstants.PATH.CREATE_JOB) {
        navigate(`${allConstants.PATH.MODULE}?${urlSearchParams.moduleName}=${allConstants.IPERMIT_MODULES.PERMIT}&${urlSearchParams.submodule}=${allConstants.IPERMIT_MODULES.ALL_JOBS}`);
      }
    }
  };

  const depersonateUser = async ()=> {

    const result = await dispatch(actions.depersonateUser());

    //console.log('result of depersonating');
    //console.log(result);

    if(result?.meta?.requestStatus === 'fulfilled'){
      navigate(allConstants.PATH.HOME);
    }
  };
  const handleOpenUserMenu = (event) => {

    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);

  };
  const logOut = async (e)=> {
    handleCloseNavMenu(e);

    await dispatch(actions.signOut());
    await dispatch(companySlice.resetCompanyState());
    return <Navigate to={allConstants.PATH.LOGIN}/>;
  };

  const userMenu =  <Box>
    <Button
      variant={'blueContained'}
      color={'secondary'}
      sx={{
        minWidth: '40px',
        height: '40px',
      }}
      size="medium"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleOpenUserMenu}
    >
      {userAcronym}
      {!userAcronym && <PersonOutlineIcon/>}
    </Button>
    <Menu
      sx={{mt: '35px'}}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem disabled className={classes.menu_text}><Typography variant={'subtitle2'}>{user.email}</Typography></MenuItem>
      <Divider />
      <MenuItem onClick={logOut}><Typography variant={'subtitle1'}>Log out</Typography></MenuItem>
    </Menu>
  </Box>;

  const depersonateButton =
    <Box marginRight={'20px'}>
      <Button variant={'contained'} color={'error'}
        size="medium"
        onClick={depersonate}
        sx={{
          paddingY: '4px',
        }}
      >
        Depersonate
      </Button>
    </Box>;

  const depersonateUserButton =
    <Box marginRight={'20px'}>
      <Button variant={'contained'} color={'error'}
        size="medium"
        onClick={depersonateUser}
        sx={{
          paddingY: '4px',
        }}
      >
        Depersonate User
      </Button>
    </Box>;

  const adminMenu = <Box marginRight={'20px'}>
    <Hidden smDown>
      <Button variant={'contained'} color={'primary'}
        size="medium" type={'text'}
        component={Link} to={allConstants.PATH.ADMIN_PAGE}
        sx={{
          paddingY: '4px',
        }}

      >
        Admin Panel
      </Button>
    </Hidden>
    <Hidden smUp>
      <IconButton aria-label="admin panel"
        sx={{
          minWidth: '40px',
          height: '40px',
          padding: '6px',
          backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: STYLE_CONSTANTS.COLORS.globalRed,
          }
        }}

        aria-controls="admin-menu-appbar"
        aria-haspopup="true"
        component={Link} to={allConstants.PATH.ADMIN_PAGE}
        onClick={()=> <Navigate to={allConstants.PATH.ADMIN_PAGE}/>}
      >
        <AdminPanelSettingsIcon style={{color: '#FFFFFF'}}/>
      </IconButton>
    </Hidden>
  </Box>;

// const onClickTag = async () => {

//   console.log('on click tag');

//   //agView.showDialog();
// }

// const tagMenu = <Box marginRight={'20px'}>
//   <Hidden smDown>
//     <Button variant={'contained'} color={'primary'}
//       size="medium" type={'text'} onClick={onClickTag}
//      // component={Link} to={allConstants.PATH.ADMIN_PAGE}
//       sx={{
//         paddingY: '4px',
//       }}
//     >
//       Tags
//     </Button>
//   </Hidden>
//   <Hidden smUp>
//     <IconButton aria-label="tags panel"
//       sx={{
//         minWidth: '40px',
//         height: '40px',
//         padding: '6px',
//         backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
//         borderRadius: '4px',
//         '&:hover': {
//           backgroundColor: STYLE_CONSTANTS.COLORS.globalRed,
//         }
//       }}
//       aria-controls="admin-menu-appbar"
//       aria-haspopup="true"
//       component={Link} to={allConstants.PATH.ADMIN_PAGE}
//       onClick={()=> <Navigate to={allConstants.PATH.ADMIN_PAGE}/>}
//     >
//       <AdminPanelSettingsIcon style={{color: '#FFFFFF'}}/>
//     </IconButton>
//   </Hidden>
//   </Box>;

  const parentCompanyId = company?.childCompanies?.length > 0 ? company.id : null;

  const impersonateUser =  isImpersonatingUser(user.role, user.impersonateUserId);
  const impersonateCompany = isImpersonating(user.role, user.companyId, user.impersonateUserId) 


  const showIpermitNavbar = !isContractor(user.role) && !impersonateCompany;

  // console.log('is impersonate comany=> ', impersonateCompany);
  // console.log('is impersonate user => ', impersonateUser);
  // console.log('show ipermit navbar => ', showIpermitNavbar);


  const allLinks = <AllLinks user={user} showIpermitLinks={showIpermitNavbar} parentCompanyId={parentCompanyId} />;

  return (
    <ThemeProvider theme={myTheme}>
      <AppBar position={'fixed'}
        className={classes.nav_bar}
        sx={{color: STYLE_CONSTANTS.COLORS.fontMain,
          backgroundColor: STYLE_CONSTANTS.COLORS.white,
          filter: user?.role ===allConstants.ROLES.SYSTEM_ADMIN ?'invert(1)' : 'none',
          border: 'none',
          boxShadow: 'none',
          justifyContent: 'center',
          borderBottom: `3px solid ${STYLE_CONSTANTS.COLORS.backgroundMain}`,

        }}>
        {/* {tagView.mainDialog()} */}
        <Box paddingX={{xs: 2}}>
          <Toolbar disableGutters sx={{alignItems: 'center'}}>
            {/*<Box minWidth={'140px'}>*/}
            <Button disableRipple variant="raised" component={Link} to={allConstants.PATH.HOME} style={{
              backgroundColor: 'transparent',
              padding: 0,
              marginRight: '40px'
            }}>
              <img src={ipermitLogo} alt={'iPermit_pro_logo.png'} className={classes.logo}/>
            </Button>
            {/*</Box>*/}
            <Box
              sx={{flexGrow: 1,height: '50px', display: {xs: 'none', lg: 'flex'}}}>
              <Box sx={{flexGrow: 1, display: {xs: 'none', lg: 'flex'}}}
                alignItems={'flex-end'} className={classes.link_wrapper}
              >
                {allLinks}

              </Box>
              <Box sx={{flexGrow: 1, display: {xs: 'none', lg: 'flex'}, justifyContent: 'flex-end', alignItems: 'center'}}>
                {impersonateCompany && <NewPermissionGate
                  user={user}
                  elementPermissions={companyRules[COMPANY_ELEMENTS.depersonateButton]}
                >
                  {depersonateButton}
                </NewPermissionGate>}
                {impersonateUser && <NewPermissionGate
                  user={user}
                  elementPermissions={companyRules[COMPANY_ELEMENTS.depersonateButton]}
                >
                  {depersonateUserButton}
                </NewPermissionGate>}

                {/* {showIpermitNavbar && <NewPermissionGate
                  user={user}
                  elementPermissions={ipermitAdminRules[ADMIN_ELEMENTS.adminPanel]}
                >{adminMenu}
                </NewPermissionGate>} */}
                <Typography variant={'subtitle1'}
                  style={{fontSize: '16px', fontWeight: '600', margin: '0 16px'}}
                >{Utils.trimUserName(usernameString)}</Typography>

                {userMenu}
              </Box>
            </Box>

            {/*small screen navbar*/}
            <Box sx={{flexGrow: 1,
              height: '50px',
              display: {xs: 'flex', lg: 'none'},
              alignItems: 'center', justifyContent: 'flex-end'}}>
              {impersonateCompany && <NewPermissionGate
                user={user}
                elementPermissions={companyRules[COMPANY_ELEMENTS.depersonateButton]}
              >
                {depersonateButton}
              </NewPermissionGate>}
              {impersonateUser && <NewPermissionGate
                  user={user}
                  elementPermissions={companyRules[COMPANY_ELEMENTS.depersonateButton]}
                >
                  {depersonateUserButton}
                </NewPermissionGate>}
              {showIpermitNavbar &&
              <NewPermissionGate user={user}
                elementPermissions={ipermitAdminRules[ADMIN_ELEMENTS.adminPanel]}>
                {adminMenu}
              </NewPermissionGate>}
              {/* {showIpermitNavbar &&
              <NewPermissionGate user={user}
                elementPermissions={ipermitAdminRules[ADMIN_ELEMENTS.adminPanel]}>
                {tagMenu}
              </NewPermissionGate>} */}
              {userMenu}
              <Box>
                <Button variant={'grayContained'} color={'primary'}
                  sx={{
                    marginLeft: '20px',
                    minWidth: '40px',
                    height: '40px',
                    padding: '6px'
                  }}

                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  // color="inherit"
                >
                  <img src={burgerIcon} alt={'menu'}/>
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: {xs: 'block', lg: 'none'},
                    '& .MuiMenu-list': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                    '& .MuiMenuItem-root': {
                      width: '100%',
                      '& a': {
                        paddingLeft: '0px',
                      }
                    },
                    '& .MuiButton-startIcon': {
                      marginLeft: '0px',
                    },
                  }}
                  className={classes.link_wrapper}
                >
                  {allLinks}
                </Menu>
              </Box>

            </Box>
          </Toolbar>
        </Box>
      </AppBar></ThemeProvider>
  );
}
export default NavBar;
